import Analytics from 'analytics';
import { AnalyticsProvider } from 'use-analytics';
import { createId } from '@paralleldrive/cuid2';

type CarketaAnalyticsPluginSessionConfig = {
  session_id: string;
};

type AnalyticsPostFields = {
  anonymous_id: string;
  session_id: string;
  component: string;
  event: string;
  url: string;
  payload: any;
  custom: any;
  created_at: Date;
};

const analyticsPost = async (fields: AnalyticsPostFields) => {
  return fetch('/api/analytics', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(fields),
  }).then((response) => response.json());
};

function getUserAgent() {
  if (typeof window !== 'undefined') {
    return window.navigator.userAgent;
  }
  return '';
}

// Note: navigator.platform is deprecated because it's unreliable and inconsistent across browsers.
// Using userAgent parsing instead for better compatibility
function getPlatform() {
  if (typeof window !== 'undefined') {
    const ua = navigator.userAgent;
    if (ua.includes('Win')) return 'Windows';
    if (ua.includes('Mac')) return 'macOS';
    if (ua.includes('Linux')) return 'Linux';
    if (ua.includes('Android')) return 'Android';
    if (ua.includes('iPhone') || ua.includes('iPad')) return 'iOS';

    return 'Unknown';
  }
  return '';
}

function isMobileDevice() {
  if (typeof window !== 'undefined') {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent
    );
  }
  return false;
}

function getDeviceType() {
  return isMobileDevice() ? 'mobile' : 'desktop';
}

function getBrowser() {
  if (typeof window !== 'undefined') {
    const ua = window.navigator.userAgent;
    if (ua.includes('Firefox')) return 'Firefox';
    if (ua.includes('Chrome') && !ua.includes('Edg')) return 'Chrome';
    if (ua.includes('Safari') && !ua.includes('Chrome')) return 'Safari';
    if (ua.includes('Edg')) return 'Edge';
    if (ua.includes('MSIE') || ua.includes('Trident/'))
      return 'Internet Explorer';
    if (ua.includes('Opera') || ua.includes('OPR')) return 'Opera';
    return 'Unknown';
  }
  return '';
}

function getBrowserVersion() {
  if (typeof window !== 'undefined') {
    const ua = window.navigator.userAgent;
    const browser = getBrowser();

    switch (browser) {
      case 'Firefox':
        return ua.match(/Firefox\/([0-9.]+)/)?.[1] || '';
      case 'Chrome':
        return ua.match(/Chrome\/([0-9.]+)/)?.[1] || '';
      case 'Safari':
        return ua.match(/Version\/([0-9.]+)/)?.[1] || '';
      case 'Edge':
        return ua.match(/Edg\/([0-9.]+)/)?.[1] || '';
      case 'Opera':
        return (
          ua.match(/OPR\/([0-9.]+)/)?.[1] ||
          ua.match(/Opera\/([0-9.]+)/)?.[1] ||
          ''
        );
      case 'Internet Explorer':
        return ua.match(/(?:MSIE |rv:)([0-9.]+)/)?.[1] || '';
      default:
        return '';
    }
  }
  return '';
}

function getOS() {
  if (typeof window !== 'undefined') {
    const ua = window.navigator.userAgent;
    if (ua.includes('Windows')) return 'Windows';
    if (ua.includes('Mac OS X')) return 'macOS';
    if (ua.includes('Linux')) return 'Linux';
    if (ua.includes('Android')) return 'Android';
    if (ua.includes('iOS') || ua.includes('iPhone') || ua.includes('iPad'))
      return 'iOS';
    return 'Unknown';
  }
  return '';
}

function getOSVersion() {
  if (typeof window !== 'undefined') {
    const ua = window.navigator.userAgent;
    const os = getOS();

    switch (os) {
      case 'Windows':
        return ua.match(/Windows NT ([0-9.]+)/)?.[1] || '';
      case 'macOS':
        return ua.match(/Mac OS X ([0-9._]+)/)?.[1]?.replace(/_/g, '.') || '';
      case 'Android':
        return ua.match(/Android ([0-9.]+)/)?.[1] || '';
      case 'iOS':
        return ua.match(/OS ([0-9_]+)/)?.[1]?.replace(/_/g, '.') || '';
      default:
        return '';
    }
  }
  return '';
}

export type AnalyticsPayload = {
  anonymousId: string;
  event: string;
  options?: Record<string, any>;
  meta: {
    ts: number;
  };
  user_agent?: string;
  platform?: string;
  device_type?: string;
  browser?: string;
  browser_version?: string;
  os?: string;
  os_version?: string;
  [key: string]: any;
};

function carketaAnalyticsPlugin() {
  let session_id = createId();
  if (sessionStorage) {
    const storedSessionId = sessionStorage.getItem(
      'carketa-analytics-session-id'
    );
    if (storedSessionId) {
      session_id = storedSessionId;
    } else {
      sessionStorage.setItem('carketa-analytics-session-id', session_id);
    }
  }
  // return object for analytics to use
  return {
    /* All plugins require a name */
    name: 'carketa-analytics-plugin',
    /* Everything else below this is optional depending on your plugin requirements */
    config: {
      session_id,
    },
    // initialize: ({
    //   config,
    // }: {
    //   config: CarketaAnalyticsPluginSessionConfig;
    // }) => {
    //   // load provider script to page
    //   // console.log('initialize', config);
    // },
    page: ({
      payload,
      config,
    }: {
      payload: AnalyticsPayload;
      config: CarketaAnalyticsPluginSessionConfig;
    }) => {
      // console.log('page', payload, config);
      const playloadUserData: AnalyticsPayload = {
        user_agent: getUserAgent(),
        platform: getPlatform(),
        device_type: getDeviceType(),
        browser: getBrowser(),
        browser_version: getBrowserVersion(),
        os: getOS(),
        os_version: getOSVersion(),
        ...payload,
      };
      analyticsPost({
        anonymous_id: payload.anonymousId,
        session_id: config.session_id,
        component: payload.options?.component || '',
        event: 'page',
        url: window.location.href,
        payload: playloadUserData,
        custom: payload.options?.custom || {},
        created_at: new Date(payload.meta.ts),
      });
    },
    track: ({
      payload,
      config,
    }: {
      payload: AnalyticsPayload;
      config: CarketaAnalyticsPluginSessionConfig;
    }) => {
      // console.log('track', payload, config);
      const playloadUserData: AnalyticsPayload = {
        user_agent: getUserAgent(),
        platform: getPlatform(),
        device_type: getDeviceType(),
        browser: getBrowser(),
        browser_version: getBrowserVersion(),
        os: getOS(),
        os_version: getOSVersion(),
        ...payload,
      };
      analyticsPost({
        anonymous_id: payload.anonymousId,
        session_id: config.session_id,
        component: payload.options?.component || '',
        event: payload.event,
        url: window.location.href,
        payload: playloadUserData,
        custom: payload.options?.custom || {},
        created_at: new Date(payload.meta.ts),
      });
    },
    // identify: ({ payload }: any) => {
    //   // call provider specific user identify method
    //   console.log('identify', payload);
    // },
    loaded: () => {
      // return boolean so analytics knows when it can send data to third-party
      return true;
    },
  };
}

interface AnalyticsWrapperProps {
  children: React.ReactNode;
}

export const AnalyticsWrapper: React.FC<AnalyticsWrapperProps> = ({
  children,
}) => {
  const analytics = Analytics({
    app: 'carketa',
    plugins: [carketaAnalyticsPlugin()],
  });
  return <AnalyticsProvider instance={analytics}>{children}</AnalyticsProvider>;
};

// Count page view
// const { page } = useAnalytics();
// useEffect(() => {
//   page();
//   // eslint-disable-next-line react-hooks/exhaustive-deps
// }, []);

// const Example = () => {
//   const { track, page, identify } = useAnalytics();
//   return (
//     <div>
//       <button
//         onClick={() =>
//           track('trackThing', { email: 'bob@bob.com', userId: 123 })
//         }
//       >
//         Track event
//       </button>
//       <button onClick={() => page()}>Trigger page view</button>
//       <button
//         onClick={() =>
//           identify('userID', { email: 'bob@bob.com', userId: 123 })
//         }
//       >
//         Trigger identify visitor
//       </button>
//     </div>
//   );
// };
